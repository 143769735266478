import { Component } from 'react';
import Modal from "react-modal";
import { Message } from "../../global/models";
import "react-datepicker/dist/react-datepicker.css";
import { ParsedProductMapInfo } from './upload_product_map_csv';
import { V1ImportProductMapInfo } from "../../gen/typescript-axios";
import { getAdminVendingMachineServiceAPI } from "../../global/api";
import { hasSpecialCharacters } from '../../has_special_characters';

interface Props {
    isOpen: boolean
    closeUploadProductMapCSVkModal(): void
    reloadProductMap(): void
    pushFlashMessage(message: Message): void;
    csvData: ParsedProductMapInfo[];
    errorMessage: string;
    vendingMachineUuid: string;
}

interface State {
}

class UploadProductMapCSVModal extends Component<Props, State> {
    closeUploadProductMapCSVModal() {
        this.props.closeUploadProductMapCSVkModal()
    }


    parseProductMap(raw: ParsedProductMapInfo): V1ImportProductMapInfo {
        return {
            product_name: raw.product_name.trim(),
            mdb_code: Number(raw.mdb_code),
            capacity: Number(raw.cap),
            restock_count: Number(raw.restock),
            low_stock_alert: Number(raw.low_stock_alert),
        }
    }


    updateProductMapCSV(e: any) {
        e.preventDefault();
        e.stopPropagation();

        const csvList = this.props.csvData.map((productMap) => {
            return this.parseProductMap(productMap);
        })

        for (let csv of csvList) {
            if (hasSpecialCharacters(csv.product_name ?? '')) {
                this.props.pushFlashMessage(new Message('Product name contains special characters. Please remove them and try again.', 'error'));
                return;
            }
        }

        console.log('csvList', csvList);

        getAdminVendingMachineServiceAPI().adminVendingMachineServiceImportProductMaps(this.props.vendingMachineUuid ?? "", {
            product_maps: csvList
        }).then((response) => {
            if (response.data.success) {
                this.closeUploadProductMapCSVModal();
                this.props.reloadProductMap();
                this.props.pushFlashMessage(new Message("ImportProductMaps", "success"))
            } else {
                this.props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                this.closeUploadProductMapCSVModal();
            }
        }).catch(() => {
            this.props.pushFlashMessage(new Message("Server error", "error"))
            this.closeUploadProductMapCSVModal();
        })
    }

    render() {
        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
            }
        };
        Modal.setAppElement('#root');

        return <Modal isOpen={this.props.isOpen} style={customStyles} onRequestClose={this.closeUploadProductMapCSVModal.bind(this)}>
            <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                    <h4 className="card-title">Batch Import Product Map</h4>
                </div>
            </div>
            <div className="iq-card-body">
                <form>
                    <div className="form-scroll" style={{ maxHeight: '65vh', overflowY: 'auto' }}>
                        <div className="form-group">
                            <label>Please Check the Product Map</label>
                            {
                                this.props.errorMessage !== '' ? <div className="alert alert-danger" role="alert">
                                    {this.props.errorMessage}
                                </div> : null
                            }
                            <table id="datatable"
                                className="table table-striped table-bordered mt-4 text-center">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>MDB Code</th>
                                        <th>Cap</th>
                                        <th>Restock</th>
                                        <th>Low Stock Alert</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.props.csvData.map((productMap, index) => {
                                            return <tr key={index}>
                                                <td>{productMap.product_name}</td>
                                                <td>{productMap.mdb_code}</td>
                                                <td>{productMap.cap}</td>
                                                <td>{productMap.restock}</td>
                                                <td>{productMap.low_stock_alert}</td>
                                            </tr>;
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
            <div className="modal-footer" style={{ display: 'flex', justifyContent: 'flex-start', padding: '20px' }}>
                {
                    this.props.errorMessage === '' ? <button onClick={this.updateProductMapCSV.bind(this)} className="btn btn-primary">Save</button> : null
                }
                <button type="button" className="btn iq-bg-danger"
                    onClick={this.closeUploadProductMapCSVModal.bind(this)}>Cancel
                </button>
            </div>
        </Modal>;
    }
}

export { UploadProductMapCSVModal };
