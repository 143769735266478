import React from 'react';
import { V1CurrencyConfig } from '../../gen/typescript-axios/api';

interface CurrencyInputProps {
    value: number;
    onChange: (value: number) => void;
    currencyConfig?: V1CurrencyConfig;
    required?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
}

const CurrencyInput: React.FC<CurrencyInputProps> = ({
    value,
    onChange,
    currencyConfig,
    required = false,
    disabled = false,
    style,
}) => {
    const decimalPlaces = currencyConfig?.currency_decimal_point ?? 0;
    const step = decimalPlaces > 0 ? `0.${'0'.repeat(decimalPlaces - 1)}1` : undefined;

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const inputValue = e.target.value;

        if (decimalPlaces === 0 && inputValue.includes('.')) {
            return;
        }

        const regex = new RegExp(`^\\d*(\\.\\d{0,${decimalPlaces}})?$`);
        if (!regex.test(inputValue)) {
            return;
        }

        const numericValue = parseFloat(inputValue) || 0;
        onChange(numericValue);
    };

    return (
        <div className="input-group" style={style}>
            <span className="input-group-text" id="basic-addon1">
                {currencyConfig?.currency_symbol}
            </span>
            <input
                type="number"
                min={"0"}
                onChange={handleValueChange}
                className="form-control"
                value={value}
                step={step}
                required={required}
                disabled={disabled}
            />
        </div>
    );
};

export default CurrencyInput;
