import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Message } from '../global/models';
import { withGlobalData, IGlobalDataContext } from '../global/global-data';
import CurrencyInput from '../product_page/CurrencyInput/CurrencyInput';
import { getAdminEmployeeGroupServiceAPI } from '../global/api';
import { formatPriceWithoutSymbol, parseCurrencyToCents } from '../util/currencyFormatter';

interface Props extends IGlobalDataContext {
}

const CreateOrEditGroupPage: React.FC<Props> = (props) => {
    const history = useHistory();
    const { id: employee_group_uuid } = useParams<{ id: string }>();
    const [name, setName] = useState<string>('');

    const [dailyAmountLimitValue, setDailyAmountLimitValue] = useState<number>(0);
    const [dailyAmountLimitEnabled, setDailyAmountLimitEnabled] = useState<boolean>(false);
    const [weeklyAmountLimitValue, setWeeklyAmountLimitValue] = useState<number>(0);
    const [weeklyAmountLimitEnabled, setWeeklyAmountLimitEnabled] = useState<boolean>(false);
    const [monthlyAmountLimitValue, setMonthlyAmountLimitValue] = useState<number>(0);
    const [monthlyAmountLimitState, setMonthlyAmountLimitState] = useState<boolean>(false);
    // States for weekly amount limits
    const [weeklyAmountLimits, setWeeklyAmountLimits] = useState<boolean[]>(Array(7).fill(false));
    const [weeklyAmountValues, setWeeklyAmountValues] = useState<number[]>(Array(7).fill(0));

    // Transaction
    const [dailyTransactionCountLimitValue, setDailyTransactionCountLimitValue] = useState<number>(0);
    const [dailyTransactionCountLimitEnabled, setDailyTransactionCountLimitEnabled] = useState<boolean>(false);
    const [weeklyTransactionCountLimitValue, setweeklyTransactionCountLimitValue] = useState<number>(0);
    const [weeklyTransactionCountLimitEnabled, setWeeklyTransactionCountLimitEnabled] = useState<boolean>(false);
    const [monthlyTransactionCountLimitValue, setMonthlyTransactionCountLimitValue] = useState<number>(0);
    const [monthlyTransactionCountLimitEnabled, setMonthlyTransactionCountLimitEnabled] = useState<boolean>(false);
    // States for weekly Transaction limits
    const [weeklyTransactionLimits, setWeeklyTransactionLimits] = useState<boolean[]>(Array(7).fill(false));
    const [weeklyTransactionValues, setWeeklyTransactionValues] = useState<number[]>(Array(7).fill(0));

    useEffect(() => {
        if (employee_group_uuid) {
            const fetchGroupDetails = async (uuid: string) => {
                getAdminEmployeeGroupServiceAPI().adminEmployeeGroupServiceGetEmployeeGroupDetail(employee_group_uuid, {})
                    .then((response) => {
                        if (response.data.success ?? false) {
                            setName(response.data.employee_group?.name ?? '');
                            setDailyAmountLimitValue(formatPriceWithoutSymbol((response.data.employee_group?.daily_amount_limit_value ?? 0), props.organization?.currency_config));
                            setDailyAmountLimitEnabled(response.data.employee_group?.daily_amount_limit_enabled ?? false);
                            setWeeklyAmountLimitValue(formatPriceWithoutSymbol((response.data.employee_group?.weekly_amount_limit_value ?? 0), props.organization?.currency_config));
                            setWeeklyAmountLimitEnabled(response.data.employee_group?.weekly_amount_limit_enabled ?? false);
                            setMonthlyAmountLimitValue(formatPriceWithoutSymbol((response.data.employee_group?.monthly_amount_limit_value ?? 0), props.organization?.currency_config));
                            setMonthlyAmountLimitState(response.data.employee_group?.monthly_amount_limit_enabled ?? false);
                            setWeeklyAmountLimits([
                                response.data.employee_group?.monday_amount_limit_enabled ?? false,
                                response.data.employee_group?.tuesday_amount_limit_enabled ?? false,
                                response.data.employee_group?.wednesday_amount_limit_enabled ?? false,
                                response.data.employee_group?.thursday_amount_limit_enabled ?? false,
                                response.data.employee_group?.friday_amount_limit_enabled ?? false,
                                response.data.employee_group?.saturday_amount_limit_enabled ?? false,
                                response.data.employee_group?.sunday_amount_limit_enabled ?? false,
                            ]);
                            setWeeklyAmountValues([
                                formatPriceWithoutSymbol((response.data.employee_group?.monday_amount_limit_value ?? 0), props.organization?.currency_config),
                                formatPriceWithoutSymbol((response.data.employee_group?.tuesday_amount_limit_value ?? 0), props.organization?.currency_config),
                                formatPriceWithoutSymbol((response.data.employee_group?.wednesday_amount_limit_value ?? 0), props.organization?.currency_config),
                                formatPriceWithoutSymbol((response.data.employee_group?.thursday_amount_limit_value ?? 0), props.organization?.currency_config),
                                formatPriceWithoutSymbol((response.data.employee_group?.friday_amount_limit_value ?? 0), props.organization?.currency_config),
                                formatPriceWithoutSymbol((response.data.employee_group?.saturday_amount_limit_value ?? 0), props.organization?.currency_config),
                                formatPriceWithoutSymbol((response.data.employee_group?.sunday_amount_limit_value ?? 0), props.organization?.currency_config),
                            ]);

                            setDailyTransactionCountLimitValue(response.data.employee_group?.daily_transaction_count_limit_value ?? 0);
                            setDailyTransactionCountLimitEnabled(response.data.employee_group?.daily_transaction_count_limit_enabled ?? false);
                            setweeklyTransactionCountLimitValue(response.data.employee_group?.weekly_transaction_count_limit_value ?? 0);
                            setWeeklyTransactionCountLimitEnabled(response.data.employee_group?.weekly_transaction_count_limit_enabled ?? false);
                            setMonthlyTransactionCountLimitValue(response.data.employee_group?.monthly_transaction_count_limit_value ?? 0);
                            setMonthlyTransactionCountLimitEnabled(response.data.employee_group?.monthly_transaction_count_limit_enabled ?? false);
                            setWeeklyTransactionLimits([
                                response.data.employee_group?.monday_transaction_count_limit_enabled ?? false,
                                response.data.employee_group?.tuesday_transaction_count_limit_enabled ?? false,
                                response.data.employee_group?.wednesday_transaction_count_limit_enabled ?? false,
                                response.data.employee_group?.thursday_transaction_count_limit_enabled ?? false,
                                response.data.employee_group?.friday_transaction_count_limit_enabled ?? false,
                                response.data.employee_group?.saturday_transaction_count_limit_enabled ?? false,
                                response.data.employee_group?.sunday_transaction_count_limit_enabled ?? false,
                            ]);
                            setWeeklyTransactionValues([
                                response.data.employee_group?.monday_transaction_count_limit_value ?? 0,
                                response.data.employee_group?.tuesday_transaction_count_limit_value ?? 0,
                                response.data.employee_group?.wednesday_transaction_count_limit_value ?? 0,
                                response.data.employee_group?.thursday_transaction_count_limit_value ?? 0,
                                response.data.employee_group?.friday_transaction_count_limit_value ?? 0,
                                response.data.employee_group?.saturday_transaction_count_limit_value ?? 0,
                                response.data.employee_group?.sunday_transaction_count_limit_value ?? 0,
                            ]);
                        } else {
                            props.pushFlashMessage(new Message(response.data.error_message ?? "Get GroupDetaill Failed", "error"));
                        }
                    })
                    .catch(() => {
                        props.pushFlashMessage(new Message("Server error", "error"));
                    })
            };

            fetchGroupDetails(employee_group_uuid);
        }
    }, [employee_group_uuid]);

    const handleSave = () => {
        const data = {
            name: name,
            daily_amount_limit_enabled: dailyAmountLimitEnabled,
            daily_amount_limit_value: parseCurrencyToCents(dailyAmountLimitValue.toString(), props.organization?.currency_config),
            weekly_amount_limit_enabled: weeklyAmountLimitEnabled,
            weekly_amount_limit_value: parseCurrencyToCents(weeklyAmountLimitValue.toString(), props.organization?.currency_config),
            monthly_amount_limit_enabled: monthlyAmountLimitState,
            monthly_amount_limit_value: parseCurrencyToCents(monthlyAmountLimitValue.toString(), props.organization?.currency_config),
            monday_amount_limit_enabled: weeklyAmountLimits[0],
            monday_amount_limit_value: parseCurrencyToCents(weeklyAmountValues[0].toString(), props.organization?.currency_config),
            tuesday_amount_limit_enabled: weeklyAmountLimits[1],
            tuesday_amount_limit_value: parseCurrencyToCents(weeklyAmountValues[1].toString(), props.organization?.currency_config),
            wednesday_amount_limit_enabled: weeklyAmountLimits[2],
            wednesday_amount_limit_value: parseCurrencyToCents(weeklyAmountValues[2].toString(), props.organization?.currency_config),
            thursday_amount_limit_enabled: weeklyAmountLimits[3],
            thursday_amount_limit_value: parseCurrencyToCents(weeklyAmountValues[3].toString(), props.organization?.currency_config),
            friday_amount_limit_enabled: weeklyAmountLimits[4],
            friday_amount_limit_value: parseCurrencyToCents(weeklyAmountValues[4].toString(), props.organization?.currency_config),
            saturday_amount_limit_enabled: weeklyAmountLimits[5],
            saturday_amount_limit_value: parseCurrencyToCents(weeklyAmountValues[5].toString(), props.organization?.currency_config),
            sunday_amount_limit_enabled: weeklyAmountLimits[6],
            sunday_amount_limit_value: parseCurrencyToCents(weeklyAmountValues[6].toString(), props.organization?.currency_config),
            daily_transaction_count_limit_enabled: dailyTransactionCountLimitEnabled,
            daily_transaction_count_limit_value: dailyTransactionCountLimitValue,
            weekly_transaction_count_limit_enabled: weeklyTransactionCountLimitEnabled,
            weekly_transaction_count_limit_value: weeklyTransactionCountLimitValue,
            monthly_transaction_count_limit_enabled: monthlyTransactionCountLimitEnabled,
            monthly_transaction_count_limit_value: monthlyTransactionCountLimitValue,
            monday_transaction_count_limit_enabled: weeklyTransactionLimits[0],
            monday_transaction_count_limit_value: weeklyTransactionValues[0],
            tuesday_transaction_count_limit_enabled: weeklyTransactionLimits[1],
            tuesday_transaction_count_limit_value: weeklyTransactionValues[1],
            wednesday_transaction_count_limit_enabled: weeklyTransactionLimits[2],
            wednesday_transaction_count_limit_value: weeklyTransactionValues[2],
            thursday_transaction_count_limit_enabled: weeklyTransactionLimits[3],
            thursday_transaction_count_limit_value: weeklyTransactionValues[3],
            friday_transaction_count_limit_enabled: weeklyTransactionLimits[4],
            friday_transaction_count_limit_value: weeklyTransactionValues[4],
            saturday_transaction_count_limit_enabled: weeklyTransactionLimits[5],
            saturday_transaction_count_limit_value: weeklyTransactionValues[5],
            sunday_transaction_count_limit_enabled: weeklyTransactionLimits[6],
            sunday_transaction_count_limit_value: weeklyTransactionValues[6],
        }

        if (employee_group_uuid) { // update
            getAdminEmployeeGroupServiceAPI().adminEmployeeGroupServiceUpdateEmployeeGroup(
                employee_group_uuid,
                data
            ).then(response => {
                if (response.data.success ?? false) {
                    props.pushFlashMessage(new Message("Group updated successfully", "success"));
                    history.push('/employee-groups');
                } else {
                    props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"));
                }
            }).catch((e) => {
                props.pushFlashMessage(new Message("server error", "error"));
            })
        } else { // create
            getAdminEmployeeGroupServiceAPI().adminEmployeeGroupServiceCreateEmployeeGroup(
                data
            ).then(response => {
                if (response.data.success ?? false) {
                    props.pushFlashMessage(new Message("Group created successfully", "success"));
                    history.push('/employee-groups');
                } else {
                    props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"));
                }
            }).catch(e => {
                props.pushFlashMessage(new Message("server error", "error"));
            })
        }
    };

    const handleCancel = () => {
        history.push('/employee-groups');
    };

    const handleWeeklyLimitChange = (index: number) => {
        const newLimits = [...weeklyAmountLimits];
        newLimits[index] = !newLimits[index];
        setWeeklyAmountLimits(newLimits);
    };

    const handleWeeklyTransactionLimitChange = (index: number) => {
        const newLimits = [...weeklyTransactionLimits];
        newLimits[index] = !newLimits[index];
        setWeeklyTransactionLimits(newLimits);
    };


    const handleWeeklyValueChange = (index: number, value: number) => {
        const newValues = [...weeklyAmountValues];
        newValues[index] = value;
        setWeeklyAmountValues(newValues);
    };


    const handleWeeklyTransactionValuesChange = (index: number, value: number) => {
        const newValues = [...weeklyTransactionValues];
        newValues[index] = value;
        setWeeklyTransactionValues(newValues);
    };

    return (
        <Fragment>
            <div id="content-page" className="content-page">
                <div className="container-fluid">

                    {/* Card for Group Name */}
                    <div className="iq-card mb-4">
                        <div className="iq-card-body">
                            <h5 className="mb-0">{employee_group_uuid ? "Edit Group" : "Add Group"}</h5>
                            <div className="form-group">
                                <label htmlFor="companyName">Group Name:</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="companyName"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    {/* Card for Value Credit Type */}
                    <div className="iq-card mb-4">
                        <div className="iq-card-body">
                            <h5 className="mb-0">Credit Type: Value</h5>

                            {/* Daily Amount Limit */}
                            <div className="d-flex align-items-center mb-3">
                                <label className="mr-2" style={{ width: '200px', marginBottom: 0 }} htmlFor="dailyAmountLimitCheckbox">
                                    Daily Amount Limit:
                                    {dailyAmountLimitEnabled ? <span style={{ color: 'red' }}>*</span> : null}
                                </label>
                                <div className="form-check mr-2" style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id="dailyAmountLimitCheckbox"
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={dailyAmountLimitEnabled}
                                        onChange={() => setDailyAmountLimitEnabled(!dailyAmountLimitEnabled)}
                                    />
                                </div>
                                <CurrencyInput
                                    value={dailyAmountLimitValue}
                                    onChange={(newValue) => setDailyAmountLimitValue(newValue)}
                                    currencyConfig={props.organization?.currency_config}
                                    required={!dailyAmountLimitEnabled}
                                    disabled={!dailyAmountLimitEnabled}
                                    style={{ width: '200px' }}
                                />
                            </div>

                            {/* Weekday Amount Limits */}
                            <div className="mb-4">
                                <label>Weekday Amount Limits:</label>
                                <div className="d-flex flex-wrap">
                                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                                        <div className="mb-3" key={index} style={{ width: 'calc(25% - 15px)', marginRight: '15px' }}>
                                            <div className="d-flex justify-content-between align-items-center ml-4" style={{ marginBottom: '5px' }}>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        id={`weeklyLimitCheckbox${index}`}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={weeklyAmountLimits[index]}
                                                        onChange={() => handleWeeklyLimitChange(index)}
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                    <label htmlFor={`weeklyLimitCheckbox${index}`} style={{ marginBottom: 0 }}>
                                                        {day}
                                                        {weeklyAmountLimits[index] ? <span style={{ color: 'red' }}>*</span> : null}
                                                    </label>
                                                </div>
                                            </div>
                                            <CurrencyInput
                                                value={weeklyAmountValues[index]}
                                                onChange={(newValue) => handleWeeklyValueChange(index, newValue)}
                                                currencyConfig={props.organization?.currency_config}
                                                required={weeklyAmountLimits[index]}
                                                style={{ width: '100%' }}
                                                disabled={!weeklyAmountLimits[index]}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Weekly Amount Limit */}
                            <div className="d-flex align-items-center mb-3">
                                <label className="mr-2" style={{ width: '200px', marginBottom: 0 }} htmlFor="weeklyLimitCheckbox">
                                    Weekly Amount Limit:
                                    {weeklyAmountLimitEnabled ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                <div className="form-check mr-2" style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id="weeklyLimitCheckbox"
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={weeklyAmountLimitEnabled}
                                        onChange={() => setWeeklyAmountLimitEnabled(!weeklyAmountLimitEnabled)}
                                    />
                                </div>
                                <CurrencyInput
                                    value={weeklyAmountLimitValue}
                                    onChange={(newValue) => setWeeklyAmountLimitValue(newValue)}
                                    currencyConfig={props.organization?.currency_config}
                                    required={!weeklyAmountLimitEnabled}
                                    style={{ width: '200px' }}
                                    disabled={!weeklyAmountLimitEnabled}
                                />
                            </div>

                            {/* Monthly Amount Limit */}
                            <div className="d-flex align-items-center mb-3">
                                <label className="mr-2" style={{ width: '200px', marginBottom: 0 }} htmlFor="monthlyLimitCheckbox">
                                    Monthly Amount Limit:
                                    {monthlyAmountLimitState ? <span style={{ color: 'red' }}>*</span> : null}
                                </label>
                                <div className="form-check mr-2" style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id="monthlyLimitCheckbox"
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={monthlyAmountLimitState}
                                        onChange={() => setMonthlyAmountLimitState(!monthlyAmountLimitState)}
                                    />
                                </div>
                                <CurrencyInput
                                    value={monthlyAmountLimitValue}
                                    onChange={(newValue) => setMonthlyAmountLimitValue(newValue)}
                                    currencyConfig={props.organization?.currency_config}
                                    required={!monthlyAmountLimitState}
                                    style={{ width: '200px' }}
                                    disabled={!monthlyAmountLimitState}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Card for Transaction Credit Type */}
                    <div className="iq-card mb-4">
                        <div className="iq-card-body">
                            <h5 className="mb-0">Credit Type: Transaction</h5>

                            {/* Daily Amount Limit */}
                            <div className="d-flex align-items-center mb-3">
                                <label className="mr-2" style={{ width: '200px', marginBottom: 0 }} htmlFor="dailyTransactionLimitCheckbox">
                                    Daily Transaction Limit:
                                    {dailyTransactionCountLimitEnabled ? <span style={{ color: 'red' }}>*</span> : null}
                                </label>
                                <div className="form-check mr-2" style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id="dailyTransactionLimitCheckbox"
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={dailyTransactionCountLimitEnabled}
                                        onChange={() => setDailyTransactionCountLimitEnabled(!dailyTransactionCountLimitEnabled)}
                                    />
                                </div>
                                <input
                                    type="number"
                                    className="form-control"
                                    min={"0"}
                                    value={dailyTransactionCountLimitValue}
                                    onChange={(e) => setDailyTransactionCountLimitValue(parseFloat(e.target.value))}
                                    required={!dailyTransactionCountLimitEnabled}
                                    disabled={!dailyTransactionCountLimitEnabled}
                                    style={{ width: '200px' }}
                                />
                            </div>

                            {/* Weekday Transaction Limits */}
                            <div className="mb-4">
                                <label>Weekday Transaction Limits:</label>
                                <div className="d-flex flex-wrap">
                                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday',].map((day, index) => (
                                        <div className="mb-3" key={index} style={{ width: 'calc(25% - 15px)', marginRight: '15px' }}>
                                            <div className="d-flex justify-content-between align-items-center ml-4" style={{ marginBottom: '5px' }}>
                                                <div className="d-flex align-items-center">
                                                    <input
                                                        id={`weeklyLimitTransactionCheckbox${index}`}
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        checked={weeklyTransactionLimits[index]}
                                                        onChange={() => handleWeeklyTransactionLimitChange(index)}
                                                        style={{ marginRight: '5px' }}
                                                    />
                                                    <label htmlFor={`weeklyLimitTransactionCheckbox${index}`} style={{ marginBottom: 0 }}>
                                                        {day}
                                                        {weeklyTransactionLimits[index] ? <span style={{ color: 'red' }}>*</span> : null}
                                                    </label>
                                                </div>
                                            </div>
                                            <input
                                                type="number"
                                                className="form-control"
                                                min={"0"}
                                                value={weeklyTransactionValues[index]}
                                                onChange={(e) => handleWeeklyTransactionValuesChange(index, parseFloat(e.target.value))}
                                                required={weeklyTransactionLimits[index]}
                                                disabled={!weeklyTransactionLimits[index]}
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {/* Weekly Transaction Limit */}
                            <div className="d-flex align-items-center mb-3">
                                <label className="mr-2" style={{ width: '200px', marginBottom: 0 }} htmlFor="weeklyLimitTransactionCheckbox">
                                    Weekly Transaction Limit:
                                    {weeklyTransactionCountLimitEnabled ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                <div className="form-check mr-2" style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id="weeklyLimitTransactionCheckbox"
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={weeklyTransactionCountLimitEnabled}
                                        onChange={() => setWeeklyTransactionCountLimitEnabled(!weeklyTransactionCountLimitEnabled)}
                                    />
                                </div>
                                <input
                                    type="number"
                                    min={"0"}
                                    className="form-control"
                                    value={weeklyTransactionCountLimitValue}
                                    onChange={(e) => setweeklyTransactionCountLimitValue(parseFloat(e.target.value))}
                                    required={!weeklyTransactionCountLimitEnabled}
                                    style={{ width: '200px' }}
                                    disabled={!weeklyTransactionCountLimitEnabled}
                                />
                            </div>

                            {/* Monthly Amount Limit */}
                            <div className="d-flex align-items-center mb-3">
                                <label className="mr-2" style={{ width: '200px', marginBottom: 0 }} htmlFor="monthlyLimitTransactionCheckbox">
                                    Monthly Transaction Limit:
                                    {monthlyTransactionCountLimitEnabled ? <span style={{ color: 'red' }}>*</span> : null}
                                </label>
                                <div className="form-check mr-2" style={{ display: 'flex', alignItems: 'center' }}>
                                    <input
                                        id="monthlyLimitTransactionCheckbox"
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={monthlyTransactionCountLimitEnabled}
                                        onChange={() => setMonthlyTransactionCountLimitEnabled(!monthlyTransactionCountLimitEnabled)}
                                    />
                                </div>
                                <input
                                    type="number"
                                    min={"0"}
                                    className="form-control"
                                    value={monthlyTransactionCountLimitValue}
                                    onChange={(e) => setMonthlyTransactionCountLimitValue(parseFloat(e.target.value))}
                                    required={!monthlyTransactionCountLimitEnabled}
                                    style={{ width: '200px' }}
                                    disabled={!monthlyTransactionCountLimitEnabled}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Action Buttons */}
                    <div className="d-flex">
                        <button type="button" className="btn btn-primary mr-2" onClick={handleSave}>
                            {employee_group_uuid ? "Update" : "Save"}
                        </button>
                        <button type="button" className="btn iq-bg-danger" onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default withGlobalData(CreateOrEditGroupPage);
