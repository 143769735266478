import React, { Fragment, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { IGlobalDataContext, withGlobalData } from "../global/global-data";
import { Message } from "../global/models";
import { useHistory } from "react-router-dom";
import { getAdminEmployeeGroupServiceAPI } from '../global/api';
import { V1EmployeeGroupBasicInfo } from '../gen/typescript-axios/api';
import { DatetimeDisplay } from '../global/datetime_display';

interface Props extends IGlobalDataContext { }

function _EmployeeGroupPage(props: Props) {
    const history = useHistory();
    const [groups, setGroups] = useState<V1EmployeeGroupBasicInfo[]>([]);

    useEffect(() => {
        loadGroups();
    }, []);

    const loadGroups = () => {
        getAdminEmployeeGroupServiceAPI().adminEmployeeGroupServiceListEmployeeGroups({
        }).then((response) => {
            if (response.data.success ?? false) {
                setGroups(response.data.list ?? []);
            } else {
                props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"));
            }
        }).catch(() => {
            setGroups([]);
            props.pushFlashMessage(new Message("Server error", "error"));
        })
    };

    const editGroup = (group: V1EmployeeGroupBasicInfo) => {
        history.push(`/employee-groups/${group.employee_group_uuid}`);
    };

    const createGroup = () => {
        history.push('/employee-groups/new');
    };

    const removeGroup = (e: React.MouseEvent<HTMLButtonElement>, group: V1EmployeeGroupBasicInfo) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm(`Are you sure you want to delete group with ${group.name}?`)) {
            console.log('group.employee_group_uuid---', group.employee_group_uuid);
            getAdminEmployeeGroupServiceAPI().adminEmployeeGroupServiceArchiveEmployeeGroup(group.employee_group_uuid ?? '', {}).then(response => {
                loadGroups();
                if (response.data.success) {
                    props.pushFlashMessage(new Message("Employee group archived", "success"))
                } else {
                    props.pushFlashMessage(new Message(response.data.error_message ?? "", "error"))
                }
            }).catch((e) => {
                props.pushFlashMessage(new Message("Server error", "error"))
            })
        }
    };

    const rows = groups.map((group, index) => (
        <tr key={index}>
            <td>{group.name}</td>
            <td> {group.created_at && <DatetimeDisplay timezone={props.timezone} timestamp={parseInt(group.created_at) ?? 0} />}</td>
            <td> {group.updated_at && <DatetimeDisplay timezone={props.timezone} timestamp={parseInt(group.updated_at) ?? 0} />}</td>
            <td>
                <button className="btn btn-warning mr-4" onClick={() => editGroup(group)}>Edit</button>
                <button className="btn btn-danger" onClick={(e) => removeGroup(e, group)}>Archive</button>
            </td>
        </tr>
    ));

    return (
        <Fragment>
            <Helmet>
                <title>{"Employee Groups - " + props.company + " - " + props.globalTitle}</title>
            </Helmet>
            <div id="content-page" className="content-page">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="iq-card">
                                <div className="iq-card-header d-flex justify-content-between">
                                    <div className="iq-header-title">
                                        <h4 className="card-title">Employee Groups Management</h4>
                                        <h6>Manage employee groups for product access control.</h6>
                                    </div>
                                    <div>
                                        <span className="btn btn-primary btn-sm" onClick={createGroup}>
                                            Create New Group
                                        </span>
                                    </div>
                                </div>
                                <div className="iq-card-body">
                                    <table className="table table-bordered table-responsive-md table-striped text-center">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Create Time</th>
                                                <th>Update Time</th>
                                                <th>Operation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export const EmployeeGroupPage = withGlobalData(_EmployeeGroupPage);
