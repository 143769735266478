import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { UserType } from "./models";
import { getAdminOrganizationServiceAPI } from "./api";
import { Ppeemployerv1Role } from '../gen/typescript-axios/api';


interface Props {
    logo: string
    userType: UserType
    roles: Ppeemployerv1Role[]
}

interface State {
    accessKeyEnabled: boolean
}

class Sidebar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            accessKeyEnabled: true,
        };
    }

    componentDidMount() {
        if (this.props.userType === UserType.ADMIN) {
            getAdminOrganizationServiceAPI().adminOrganizationServiceGetOrganizationDetail().then((response) => {
                if (response.data.success) {
                    if (!response.data.organization?.pickup_methods?.mifare_card) {
                        this.setState({ accessKeyEnabled: false });
                    }
                } else {
                    console.log(response.data.error_message ?? "")
                }
            }).catch(() => {
                console.log("Server error")
            })
        }
    }

    render() {
        return (
            <aside>
                <div className="iq-sidebar">
                    <div className="iq-navbar-logo d-flex justify-content-between">
                        <Link to={"/"} className="header-logo">
                            <img src={`data:image/jpeg;base64,${this.props.logo}`} className="img-fluid" alt=""
                                id="company_logo" />
                        </Link>
                        <div className="iq-menu-bt align-self-center">
                            <div className="wrapper-menu">
                                <div className="hover-circle"><i className="ri-menu-line"></i></div>
                                <div className="main-circle"><i className="ri-close-fill"></i></div>
                            </div>
                        </div>
                    </div>
                    <div id="sidebar-scrollbar">
                        <nav className="iq-sidebar-menu">
                            {this.props.userType === UserType.ADMIN && this.props.roles.includes(Ppeemployerv1Role.Admin) &&
                                <ul id="iq-sidebar-toggle" className="iq-menu">
                                    <li>
                                        <Link to={"/"}>
                                            <i className="las la-home iq-arrow-left"></i
                                            ><span>Dashboard</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/transactions"}>
                                            <i className="las la-laptop-code iq-arrow-left"></i
                                            ><span>Transaction Records</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/employees"}>
                                            <i className="las la-user iq-arrow-left"></i
                                            ><span>Employee Management</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={"/employee-groups"}>
                                            <i className="las la-users iq-arrow-left"></i>
                                            <span>Employee Groups</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/products"}>
                                            <i className="las la-tools iq-arrow-left"></i
                                            ><span>Products</span>
                                        </Link>
                                    </li>
                                    {this.state.accessKeyEnabled &&
                                        <li>
                                            <Link to={"/key_card_tap_events"}>
                                                <i className="las la-id-card iq-arrow-left"></i
                                                ><span>Key Card Tap Events</span>
                                            </Link>
                                        </li>
                                    }
                                    <li>
                                        <Link to={"/logs"}>
                                            <i className="las la-file-contract iq-arrow-left"></i
                                            ><span>Audit Logs</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/feedback"}>
                                            <i className="las la-file-contract iq-arrow-left"></i
                                            ><span>Feedbacks</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/trackable_items"}>
                                            <i className="las la-boxes iq-arrow-left"></i
                                            ><span>Trackable Items</span>
                                        </Link>
                                    </li>


                                    <li>
                                        <Link to={"/jobs"}>
                                            <i className="las la-hammer iq-arrow-left"></i
                                            ><span>Jobs</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/tags"}>
                                            <i className="las la-tags iq-arrow-left"></i
                                            ><span>Tags</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/scheduled-reports"}>
                                            <i className="las la-clock iq-arrow-left"></i
                                            ><span>Scheduled Reports</span>
                                        </Link>
                                    </li>

                                    <hr />

                                    <li>
                                        <Link to={"/vending_machines"}>
                                            <i className="ri-pages-line iq-arrow-left"></i
                                            ><span>Vending Machines</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/lockers"}>
                                            <i className="las la-th iq-arrow-left"></i
                                            ><span>Standalone Lockers</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/dedicated_lockers"}>
                                            <i className="las la-th iq-arrow-left"></i
                                            ><span>Dedicated Lockers</span>
                                        </Link>
                                    </li>

                                    <hr />

                                    <li>
                                        <Link to={"/company"}>
                                            <i className="las la-file-contract iq-arrow-left"></i>
                                            <span>Organization Settings</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/integrations/servicenow"}>
                                            <i className="las la-file-contract iq-arrow-left"></i>
                                            <span>ServiceNow Integration</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={"/self-servicing"}>
                                            <i className="las la-check-circle iq-arrow-left"></i>
                                            <span>Self-Service Onboarding</span>
                                        </Link>
                                    </li>
                                    <hr />
                                    <li>
                                        <a href={"/manual"}>
                                            <i className="las la-book iq-arrow-left"></i>
                                            <span>User Manual</span>
                                        </a>
                                    </li>

                                    <li>
                                        <Link to={"/help"}>
                                            <i className="las la-question iq-arrow-left"></i>
                                            <span>Help</span>
                                        </Link>
                                    </li>
                                </ul>
                            }

                            {
                                this.props.userType === UserType.ADMIN &&
                                this.props.roles.includes(Ppeemployerv1Role.Report) &&
                                !this.props.roles.includes(Ppeemployerv1Role.Admin) &&
                                <ul id="iq-sidebar-toggle" className="iq-menu">
                                    <li>
                                        <Link to={"/transactions"}>
                                            <i className="las la-laptop-code iq-arrow-left"></i
                                            ><span>Transaction Records</span>
                                        </Link>
                                    </li>
                                </ul>
                            }

                            {
                                this.props.userType === UserType.ADMIN &&
                                this.props.roles.includes(Ppeemployerv1Role.EmployeeManagement) &&
                                !this.props.roles.includes(Ppeemployerv1Role.Admin) &&
                                <ul id="iq-sidebar-toggle" className="iq-menu">
                                    <li>
                                        <Link to={"/employees"}>
                                            <i className="las la-user iq-arrow-left"></i
                                            ><span>Employee Management</span>
                                        </Link>
                                    </li>
                                </ul>
                            }

                            {
                                this.props.userType === UserType.ADMIN &&
                                this.props.roles.includes(Ppeemployerv1Role.EmployeeManagement) &&
                                !this.props.roles.includes(Ppeemployerv1Role.Admin) &&
                                <ul id="iq-sidebar-toggle" className="iq-menu">
                                    <li>
                                        <Link to={"/employee-groups"}>
                                            <i className="las la-users iq-arrow-left"></i>
                                            <span>Employee Groups</span>
                                        </Link>
                                    </li>
                                </ul>
                            }


                            {
                                this.props.userType === UserType.ADMIN &&
                                this.props.roles.includes(Ppeemployerv1Role.VendingMachineManagement) &&
                                !this.props.roles.includes(Ppeemployerv1Role.Admin) &&
                                <ul id="iq-sidebar-toggle" className="iq-menu">
                                    <li>
                                        <Link to={"/vending_machines"}>
                                            <i className="ri-pages-line iq-arrow-left"></i
                                            ><span>Vending Machines</span>
                                        </Link>
                                    </li>
                                </ul>
                            }


                            {
                                this.props.userType === UserType.EMPLOYEE &&
                                <ul id="iq-sidebar-toggle" className="iq-menu">
                                    <li>
                                        <Link to={"/"}>
                                            <i className="las la-laptop-code iq-arrow-left"></i
                                            ><span>Transaction</span>
                                        </Link>
                                    </li>
                                </ul>
                            }
                            {
                                this.props.userType === UserType.EMPLOYEE &&
                                <ul id="iq-sidebar-toggle" className="iq-menu">
                                    <li>
                                        <Link to={"/security_sessions"}>
                                            <i className="las la-shield-alt iq-arrow-left"></i
                                            ><span>Security</span>
                                        </Link>
                                    </li>
                                </ul>
                            }
                            {
                                this.props.userType === UserType.PARTNER &&
                                <ul id="iq-sidebar-toggle" className="iq-menu">
                                    <li>
                                        <Link to={"/partners"}>
                                            <i className="las la-shield-alt iq-arrow-left"></i
                                            ><span>Partners</span>
                                        </Link>
                                    </li>
                                </ul>
                            }
                        </nav>
                        <div className="p-3"></div>
                    </div>
                </div>

            </aside>
        )
    }
}

export { Sidebar };
